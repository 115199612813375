<template>
  <div class="col school_teachers_page_wrapper">
    <div class="every_table_action_button">
      <button class="our_btn" @click="showAddClassDialog = true">
        <el-icon class="el-icon-plus"></el-icon>
        {{ $t("admin_dashboard_type.add_class") }}
      </button>
    </div>

    <server-table
      :DataItems="groupsData"
      :count="count"
      @Refresh="Refresh"
      :useFilter="false"
      @export="Export"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="group_name"
          :label="$t('admin_dashboard_type.class_name')"
        >
          <template slot-scope="scope">
            <router-link :to="`/school/${scope.row.id}`">
              {{ scope.row.group_name }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_name"
          :label="$t('admin_dashboard_type.teacher_name')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.teacher_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_name"
          :label="$t('admin_dashboard_type.level')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.grade ? `Grade ${scope.row.grade}` : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="city" :label="$t('admin_dashboard_type.city')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.city ? scope.row.city : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="users_count"
          :label="$t('admin_dashboard_type.students_number')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.users_count }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('Global.status')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0"
              round
              size="small"
              type="danger"
            >
              {{ $t("non-active") }}
            </el-button>
            <el-button
              v-if="scope.row.status == 2"
              round
              size="small"
              type="primary"
            >
              {{ $t("active") }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="group_date"
          :label="$t('admin_dashboard_type.date')"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.row.group_date
                  ? toLocalDatetime(scope.row.group_date, "en")
                  : "_"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="_">
          <template slot-scope="scope">
            <div class="icons_table_wrapper">
              <i
                @click="removeClass(scope.row.id)"
                class="fa fa-trash shadow-sm icon remove"
                v-tooltip.top-center="$t('admin_dashboard_type.delete_class')"
              ></i>
              <i
                class="fa fa-edit shadow-sm icon edit"
                @click="openEditDialog(scope.row)"
                v-tooltip.top-center="$t('admin_dashboard_type.edit_class')"
              ></i>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>

    <!-- Start Add Class -->
    <el-dialog
      :visible.sync="showAddClassDialog"
      :title="
        isEdit == false
          ? $t('admin_dashboard_type.add_class')
          : $t('admin_dashboard_type.edit_class')
      "
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        :model="classData"
        :rules="addClassRules"
        ref="addClassForm"
        class="demo-ruleForm"
        label-position="top"
      >
        <div class="row">
          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.class_name')"
              prop="name"
              min-width="80"
              :rules="[
                {
                  required: true,
                  message: `${this.$t('field_required')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :label="$t('admin_dashboard_type.class_name')"
                v-model="classData.name"
                class="w-100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.courses')"
              prop="course_id"
              :rules="[
                {
                  required: true,
                  message: `${this.$t('field_required')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <multiselect
                :placeholder="$t('admin_dashboard_type.choose_course')"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                v-model="classData.course_id"
                :multiple="false"
                :options="allCoursesData"
                :custom-label="getCourseTitle"
              ></multiselect>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.level')"
              prop="grade"
              :rules="[
                {
                  required: true,
                  message: `${this.$t('field_required')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <multiselect
                :placeholder="$t('admin_dashboard_type.level')"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                v-model="classData.grade"
                :multiple="false"
                :options="grades"
                label="title"
                track-by="id"
              ></multiselect>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.days')"
              prop="days"
              :rules="[
                {
                  required: true,
                  message: `${this.$t('field_required')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <multiselect
                :placeholder="$t('admin_dashboard_type.choose_days')"
                :searchable="true"
                :show-labels="false"
                :close-on-select="false"
                v-model="classData.days"
                :multiple="true"
                :options="days"
              ></multiselect>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.start_from')"
              prop="start_from"
              :rules="[
                {
                  required: true,
                  message: `${this.$t('field_required')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :label="$t('admin_dashboard_type.start_from')"
                v-model="classData.start_from"
                class="w-100"
                type="date"
              ></el-input>
            </el-form-item>
          </div>

          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.end_at')"
              prop="end_at"
              :rules="[
                {
                  required: true,
                  message: `${this.$t('field_required')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :label="$t('admin_dashboard_type.end_at')"
                v-model="classData.end_at"
                class="w-100"
                type="date"
                format="yyyy-MM-dd"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.age')"
              prop="age_group"
              :rules="[
                {
                  required: true,
                  message: `${this.$t('field_required')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <multiselect
                :placeholder="$t('admin_dashboard_type.age')"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                v-model="classData.age_group"
                :multiple="false"
                :options="age_group"
                label="title"
                track-by="id"
              ></multiselect>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="creative_app_footer_dialog">
        <el-button class="when_submit_form save_from" @click="handlerFunction">
          {{ $t("admin_dashboard_type.save") }}</el-button
        >
        <el-button
          class="when_submit_form cancel_from"
          @click="handlerCloseFunction"
        >
          {{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- End Add Class -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      showAddClassDialog: false,
      classData: {
        name: null,
        course_id: null,
        grade: null,
        start_from: null,
        end_at: null,
        days: [],
        age_group: null,
      },
      addClassRules: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        course_id: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        age_group: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        start_from: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        end_at: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      days: [
        this.$t("saturday"),
        this.$t("sunday"),
        this.$t("monday"),
        this.$t("tuesday"),
        this.$t("wednesday"),
        this.$t("thursday"),
        this.$t("friday"),
      ],
      grades: [
        {
          id: 1,
          title: "Grade 1",
        },
        {
          id: 2,
          title: "Grade 2",
        },
        {
          id: 3,
          title: "Grade 3",
        },
        {
          id: 4,
          title: "Grade 4",
        },
        {
          id: 5,
          title: "Grade 5",
        },
        {
          id: 6,
          title: "Grade 6",
        },
        {
          id: 7,
          title: "Grade 7",
        },
        {
          id: 8,
          title: "Grade 8",
        },
        {
          id: 9,
          title: "Grade 9",
        },
        {
          id: 10,
          title: "Grade 10",
        },
        {
          id: 11,
          title: "Grade 11",
        },
        {
          id: 12,
          title: "Grade 12",
        },
      ],
      age_group: [
        { id: 0, title: this.$t("less_than_6") },
        { id: 1, title: 6 },
        { id: 2, title: 7 },
        { id: 3, title: 8 },
        { id: 4, title: 9 },
        { id: 5, title: 10 },
        { id: 6, title: 11 },
        { id: 7, title: 12 },
        { id: 8, title: this.$t("more_than_12") },
      ],
      datePickerOptions: {
        disabledDate(date) {
          return date <= new Date();
        },
      },
      isEdit: false,
      getClassId: null,
    };
  },
  components: { ServerTable, DatePicker },
  mounted() {
    this.Refresh();
    this.getAllCourses();
  },
  computed: {
    groupsData() {
      return this.$store.getters["user/get_schools_group"];
    },
    count() {
      return this.$store.getters["user/get_schools_group_count"];
    },
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    allCoursesData() {
      console.log("zzzzzzzz",this.$store.getters["courses/courses"]);
      return this.$store.getters["courses/courses"];
    },
    handlerFunction() {
      return this.isEdit == false ? this.addSchoolClass : this.editSchoolClass;
    },
    handlerCloseFunction() {
      return this.isEdit == false ? this.closeAddDialog : this.closeEditDialog;
    },
  },
  methods: {
    Refresh(
      query = { offset: this.offset, limit: this.limit, order_by: "id" }
    ) {
      this.$store.dispatch("user/handlerSchoolsGroup", { query: query });
    },
    getCourseTitle(title) {
      return this.getLocalizedText(title.title);
    },
    getCourseDescription(description) {
      return this.getLocalizedText(description);
    },
    getLessonTitle(title) {
      return this.getLocalizedText(title);
    },
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          console.log("Parsed Data:", parsedData);
          return parsedData[locale] || parsedData["en"]; // Fallback to English if the locale is not found
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return jsonString; // Return the original string if it's not valid JSON
        }
      } else {
        return "";
      }
    },
    getAllCourses(query) {
      this.$store
        .dispatch("courses/list", { query: query })
        .then((response) => {
          console.log("response", response);
          // this.coursesData = this.$store.getters["courses/courses"];
        });
    },
    Export(query) {
      this.$store.dispatch("user/export", { query: query });
    },
    addSchoolClass() {
      this.$refs["addClassForm"].validate((valid) => {
        if (valid) {
          this.classData.course_id = this.classData.course_id.id;
          this.classData.grade = this.classData.grade.id;
          this.classData.age_group = this.classData.age_group.id;
          this.$store
            .dispatch("schools/handlerAddSchoolClass", this.classData)
            .then(() => {
              this.showAddClassDialog = false;
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_added")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
              this.classData.name = null;
              this.classData.course_id = null;
              this.classData.grade = null;
              this.classData.start_from = null;
              this.classData.end_at = null;
              this.classData.days = null;
              this.classData.age_group = null;
            });
        } else {
          return false;
        }
      });
    },
    removeClass(id) {
      console.log(id);
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("groups/deleteGroup", id).then((_) => {
            this.Refresh();
            Swal.fire({
              text: `${this.$t("success_deleted")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
    whichSpecialization(pcValue, anyArray) {
      if (pcValue != null) {
        let specialize = anyArray.filter((item) => {
          if (item.id == pcValue) {
            return item;
          }
        });

        return specialize[0].title;
      } else {
        return "__";
      }
    },
    openEditDialog(item) {
      this.isEdit = true;
      console.log(item);
      this.getClassId = item.id;
      this.showAddClassDialog = true;
      this.classData.name = item.group_name;
      this.classData.days = item.days;
      // grade
      if (item.grade != null) {
        this.classData.grade = {
          id: item.grade,
          title: this.whichSpecialization(item.grade, this.grades),
        };
      }
      // age group
      if (item.age_group != null) {
        this.classData.age_group = {
          id: item.age_group,
          title: this.whichSpecialization(item.age_group, this.age_group),
        };
      }
      // allCoursesData
      if (item.course_id != null) {
        this.classData.course_id = {
          id: item.course_id,
          title: this.whichSpecialization(item.course_id, this.allCoursesData),
        };
      }
      // start from - end at
      this.classData.start_from = item.start_from.split(" ")[0];
      this.classData.end_at = item.end_at.split(" ")[0];
    },
    editSchoolClass() {
      let afterUpdateTeacherData = {
        name: null,
        course_id: null,
        grade: null,
        start_from: null,
        end_at: null,
        days: [],
        age_group: null,
      };
      this.classData.course_id = this.classData.course_id.id;
      this.classData.grade = this.classData.grade.id;
      this.classData.age_group = this.classData.age_group.id;
      this.showAddClassDialog = false;
      this.$refs["addClassForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("groups/updateGroup", {
              data: this.classData,
              id: this.getClassId,
            })
            .then((_) => {
              this.Refresh();
              this.showAddClassDialog = false;
              this.isEdit = false;
              this.getClassId = null;
              this.classData = afterUpdateTeacherData;
              Swal.fire({
                text: `${this.$t("success_edited")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
            });
        }
      });
    },
    closeAddDialog() {
      this.showAddClassDialog = false;
      this.isEdit = false;
    },
    closeEditDialog() {
      this.isEdit = false;
      this.showAddClassDialog = false;
      this.getClassId = null;
      let afterUpdateTeacherData = {
        name: null,
        course_id: null,
        grade: null,
        start_from: null,
        end_at: null,
        days: [],
        age_group: null,
      };
      this.classData = afterUpdateTeacherData;
    },
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}

::v-deep .el-icon-arrow-right:before {
  content: "\e6de";
}

::v-deep .cell {
  text-align: center;
  word-break: break-word;
}

::v-deep sup {
  top: 0;
}

::v-deep sup.el-badge__content {
  height: unset;
  padding: 5px 20px;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

::v-deep .el-radio {
  margin-left: 30px;
  margin-right: 0;
}

::v-deep .el-radio__input {
  margin-left: 10px;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}

/* rate icons  */
::v-deep .activity-rate .el-icon-star-on:before {
  font-size: 3rem;
}

::v-deep .activity-rate .el-icon-star-off:before {
  font-size: 3rem;
}

::v-deep .el-rate__icon.fa-shield {
  font-size: 3rem;
  margin-right: 1.2rem;
}

::v-deep .el-rate__text {
  margin-right: 1rem;
  font-size: 2rem;
}
</style>

<style lang="scss" scoped>
.icons_table_wrapper {
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 5px 0;
  .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    &.edit {
      background-color: #409eff;
      color: #fff;
    }
    &.remove {
      background-color: #fb404b;
      color: #fff;
    }
  }
}
.every_table_action_button {
  padding: 10px 0;
  .our_btn {
    background-color: #409eff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: 0.5;
    border: 1px solid #409eff;
    &:hover {
      opacity: 0.8;
    }
  }
}
.creative_app_footer_dialog {
  display: flex;
  margin-top: 15px;
  justify-content: end;
  padding: 10px;
  gap: 10px;
  .when_submit_form {
    min-width: 150px;
    text-align: center;
    transition: 0.3s;
    &.save_from {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
    }
    &.cancel_from {
      border: 1px solid #fb404b;
      background-color: #fb404b;
      color: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>

<style lang="scss">
.swal2-actions {
  gap: 10px;
  display: flex;
  .swal2-cancel {
    margin-bottom: 0;
  }
  .swal2-confirm {
    border: 1px solid #3498db;
  }
}
.school_teachers_page_wrapper {
  .el-dialog__header {
    text-align: initial !important;
    text-transform: capitalize;
  }
  .el-form-item__label {
    width: 100%;
    text-transform: capitalize;
    text-align: initial !important;
  }
  .el-form-item__error {
    width: 100%;
    text-align: initial;
  }
}
</style>
